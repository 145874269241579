.bg-1 {
  background-color: #4a6543 !important;
}

.white {
  color: #ffffff !important;
}

.letter-spacing {
  letter-spacing: 1px;
}

.border-red {
  border: 1px solid red;
}

.color-new {
  color: #4a6543 !important;
}

.sidebar-board {
  /* border: 1px solid red; */
  /* height: 100%; */
}

.bg-2 {
  /* background-color: fafafa !important; */
}

.row {
  /* border: 1px solid yellow; */
  /* width: 100vw; */
}

.main-dashboard-area {
  overflow: hidden;
}

.Maingraph-Dash-box {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 20px;
}

.MainDash-table-box {
  width: 40%;
  height: 350px;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 10px;
  background-color: #4a65433d;
  box-shadow: -4px 0px 100px 11px rgba(0, 0, 0, 0.1);
}

.MainDash-table-box::-webkit-scrollbar {
  width: 4px;
  height: 100px;
}

.MainDash-table-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.MainDash-table-box::-webkit-scrollbar-thumb {
  background-color: #4a6543;
  border-radius: 6px;
  height: 100px;
}

.MainDash-table-box::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.719) 543;
}

.MainDash-graph-box {
  width: 60%;
  /* border: 1px solid red; */
}

.MainDash-table-box table {
  position: relative;
  width: 100%;
}

.MainDash-table-box table thead {
  position: sticky;
  top: 0;
}

.MainDash-table-box table thead tr {
  background-color: #4a6543;
}

.MainDash-table-box table thead tr th {
  color: #fff;
  letter-spacing: 1.5px;
  padding: 5px 5px 5px 10px;
}

.MainDash-table-box table tbody tr {
  border-bottom: 1px solid #a3b1a0;
}

.MainDash-table-box table tbody tr td {
  padding: 5px 5px 5px 10px;
  color: #5c727d;
  /* border: 1px solid red; */
}

.MainDash-table-box table tbody tr td:last-child {
  padding: 5px 10px 5px 10px;
}

.container {
  max-width: 100% !important;

  padding: 0 !important;
}

.nav {
  background-color: #2a4025;
  margin-right: 0 !important;
  padding-right: 0 !important;

  border: none !important;
}

.nav a:not(.Logout-a) {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  /* color: #4A6543; */
}

.accordion-body {
  background-color: #2a4025;
  overflow-x: auto;
}

.accordion-body a {
  font-size: 18px !important;
}
.accordion-body::-webkit-scrollbar {
  display: none !important;
}
.customTable {
  overflow-x: auto !important;
}
.Logout-a {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  cursor: pointer;
}

.fas.fa-sign-out-alt {
  color: #ffffff;
  /* color: #4A6543; */
}

.dashboard-shift {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a4025;
  color: #fff;
  padding: 20px 20px;
  font-size: 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  text-align: center;
  font-weight: 700;
  width: 100%;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 20px;
}

.dashboard-shift.active {
  background-color: #4a6543;
}

.fas fa-home {
  width: 20px;
  height: 20px;
}

.accordion-button {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: #4a6543 !important;
  color: #ffffff !important;
  letter-spacing: 2px !important;
}

.accordion-collapse {
  background-color: #2a4025 !important;
}

.accordion-button.collapsed {
  background-color: #2a4025 !important;
}

.accordion-button.collapsed:focus .dashboard-shift {
  background-color: red !important;
}

.dashboard-sidebar {
  min-height: 100vh;
  background-color: #2a4025;
  padding: 0 !important;
  width: 20%;
}

.logo-dash {
  margin: 20px 0px 10px 20px;
  width: 150px;
}

.dashboard-text-header {
  padding: 50px 20px 10px 20px;
  /* margin-bottom: 10px; */
  background-color: #2a4025;
  text-align: center;
}

.dashboard-text-header h2 {
  color: #ffffff;
  font-size: 40px;
}

.right-content-dashboard {
  padding: 0 !important;
  width: 80%;
}

.right-content-dashboard > p {
  padding: 20px;
  background-color: var(--section-bg-1);
}

.col-lg-12 {
  padding: 0;
}

ol {
  padding: 0px 35px !important;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.Sercive-1 {
  width: 100%;
  color: #ffffff;
  letter-spacing: 1px;
  background-color: #4a6543;
}
.input-item label {
  color: #212529;
  letter-spacing: 1px;
  width: 100%;
  background-color: #4a654371;
}
.input-item label input {
  margin-right: 10px;
}

input:disabled {
  background-color: rgb(237 237 237);
}

select:disabled {
  background-color: rgb(237 237 237);
  pointer-events: none;
}


/* New */
/* @media (max-width: 1463px) {
    .input-item{
        border: 1px solid red;
    }
} */
@media (max-width: 1330px) {
  .Maingraph-Dash-box {
    flex-direction: column;
  }

  .MainDash-table-box {
    width: 100%;
  }

  .MainDash-graph-box {
    width: 100%;
  }
}

@media (max-width: 1430px) {
  .dashboard-sidebar {
    width: 300px !important;
    /* font-size: 13px; */
  }
  .right-content-dashboard {
    width: calc(100% - 300px);
  }
}

@media (max-width: 992px) {
  .tab-content {
    padding: 0px 0px 0px 30px;
    overflow: scroll !important;
  }

  .dashboard-text-header h2 {
    padding-left: 30px;
  }

  .dashboard-sidebar {
    /* height: 100% !important; */
    min-height: 100% !important;
  }

  .dashboard-text-header {
    padding-top: 70px;
  }
  table {
    overflow-x: auto !important;
    width: fit-content !important;
  }
}

/* Loader */

.loader-container-dash {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #2a4025 !important;
}

.loader-dash {
  border: 4px solid #ffffff;
  border-left-color: #0e0d0d;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  background-color: transparent;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
