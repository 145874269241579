.contractpdf-sec {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;
  margin: 0px auto;

  .car-img {
    width: 300px;
    height: 300px;
    height: auto;
    object-fit: cover;
  }
  .pdf-logo {
    width: 200px;
    height: 62.625px;
    object-fit: cover;
    margin: 0px 0px 20px 30px;
  }
  p {
    font-size: 14px;
    line-height: 120%;
    color: black;
    margin-bottom: 0px;
    &:first-of-type {
      margin-top: 0px !important;
    }
  }
  .car-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .detail-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
    .left {
      background-color: rgb(238, 237, 237);
      padding: 15px 0px;
      border: 1px dashed gray;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h3 {
        font-size: 18px;
        padding-top: 10px;
        margin-bottom: 10px;
      }
      .cust-det {
        .ist,
        .second,
        .third {
          display: flex;
          align-items: center;
        }
        .ist {
          column-gap: 80px;
        }
        .second {
          column-gap: 40px;
        }
        .third {
          column-gap: 98px;
        }
      }

      .img-div {
        background: white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;
        img {
          width: 200px;
          height: 60px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px dashed gray;

      h4 {
        font-size: 16px;
        text-align: center;
        margin-bottom: 15px;
      }
      span {
        text-align: center;
        font-size: 14px;
        line-height: 120%;
      }
    }
  }
  .detait-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .det-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .second-logo {
        width: 200px;
        height: 60px;
        object-fit: cover;
      }
      span {
        margin-left: auto;
        font-size: 14px;
        font-weight: bold;
        font-weight: bold;
        color: black;
      }
    }
    .customer-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .head {
        font-size: 16px;
        color: white;
        background: black;
        width: fit-content;
        padding: 0px 5px;
      }
      .info-detail {
        display: grid;
        grid-template-columns: 250px 150px 200px 200px;
        div {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          padding-right: 20px;
          h5 {
            font-size: 14px;
          }
        }
      }
    }
    .vehicle-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .head {
        font-size: 16px;
        color: white;
        background: black;
        width: fit-content;
        padding: 0px 5px;
      }
      .info-detail {
        display: grid;
        grid-template-columns: 240px 60px 150px 100px 250px;
        div {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          padding-right: 20px;
          h5 {
            font-size: 14px;
          }
        }
      }
    }
    .services-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .head {
        font-size: 16px;
        color: white;
        background: black;
        width: fit-content;
        padding: 0px 5px;
      }
      .info-detail {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
        div {
          display: grid;
          grid-template-columns: 500px 150px 150px;

          h5 {
            font-size: 14px;
          }
        }
      }
    }
    .selling-dealer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .head {
        font-size: 16px;
        color: white;
        background: black;
        width: fit-content;
        padding: 0px 5px;
      }
      .info-detail {
        display: grid;
        grid-template-columns: 200px 270px 150px 180px;
        div {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          padding-right: 20px;
          h5 {
            font-size: 14px;
          }
        }
      }
    }
    .our-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .head {
        font-size: 16px;
        color: white;
        background: black;
        width: fit-content;
        padding: 0px 5px;
      }
      .info-detail {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        div {
          display: flex;
          flex-direction: column;

          padding-right: 20px;
          h5 {
            font-size: 14px;
          }
        }
      }
    }
    .caution {
      display: flex;
      justify-content: center;
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .signs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    margin: 30px 0px;
    .ist {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;

      .date {
        text-align: center;
      }
    }
    .third {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;

      span {
        width: 100%;
        height: 2px;
        background: black;
      }
    }
    .second {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;

      span {
        font-size: 12px;
        color: black;
        text-align: center;
        line-height: 110%;
      }
    }
  }
  .terms {
    h5 {
      font-size: 16px;
      margin-bottom: 15px;
      text-align: center;
    }
    ol {
      margin-bottom: 10px;
      li {
        margin: 0px;
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 2px;
      }
    }
    .definitions-div {
      margin-bottom: 10px;
    }
  }
  .under-text {
    text-decoration: underline;
  }
  .first-para {
    margin-bottom: 10px;
  }
}
.contractpdf-sec div {
  page-break-inside: avoid;
  display: table;
}
@page {
  margin-top: 20px !important;
}
@media print {
  .contractpdf-sec div {
    break-inside: avoid;
  }
}

//modal

.confirmmodal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  background-color: transparent !important;
}
.confirmmodal .modal-body h4 {
  font-size: 30px;
  font-weight: 600;
  line-height: 100%;
}
.confirmmodal .modal-body p {
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
}
.confirmmodal .modal-body p,
h4 {
  margin: 0px;
}
.confirmmodal .modal-body button {
  margin-top: 30px;
  padding: 8px 0px;
  width: 200px;
  background: #3f5a38;
  border-radius: 8px;
  color: white;
  font-family: var(--body-font);
}
