.img-attachment {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.video-attachment {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.file-attachment-container {
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  border: 1px solid white;
  position: relative;
}


.file-attachment {
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px;
}