/* .tab-content {
  width: 100%;
}

.tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  border-radius: 2px solid red !important;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
} */

.error-message {
  color: red;
}

.tableListServiceManager tr td {
  line-break: anywhere;
}

.tableListServiceManager tr th {
  line-break: anywhere;
}
