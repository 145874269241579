.main-pagination-container {
  display: flex;
}

.instruction {
  margin-right: 1.0rem;
  margin-left: 0.5rem;
}
.rows-per-page {
  margin-right: 1rem;
}
.rows-per-page select {
  margin-left: 1rem;
}