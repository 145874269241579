.isSelectedReportLog {
  max-height: 650px !important;
}

.zeroPadding {
  padding: 0px !important;
}

.scrollableContent {
  position: relative;
  width: 100%;
  overflow-y: scroll;
}

.scrollableContent::-webkit-scrollbar {
  width: 13px;
}

.scrollableContent::-webkit-scrollbar-thumb {
  background: white;
  padding-left: 5px;
  border-radius: 5px;
  border-left: 7px solid transparent;
  background-clip: padding-box;
  margin-top: 5px;
}

.scrollableContent::-webkit-scrollbar-track {
  background: transparent;
}
