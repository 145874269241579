@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .dash-aside {
    width: 100% !important;
    min-height: 100vh !important;
    background-color: red;
    background: #2a4025;
    position: fixed;
    transition: all 0.3s linear;
    left: -100%;
    z-index: 99;
    margin-top: 0 !important;
    &.active {
      transition: all 0.3s linear;

      left: 0;
    }
    .ltn__tab-menu-list {
      .nav {
        padding-top: 30px;
      }
    }
    .dashboard-shift {
      margin-top: 0;
    }
  }
  .dashboard-text-header {
    padding: 0px 0px 10px 0px !important;
    /* margin-bottom: 10px; */
    background-color: #2a4025;
    text-align: center;
  }
  .ac-Body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-sm-none {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .accordion {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
