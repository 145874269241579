.summary-card {
  margin: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.accent-text {
  color: #2a4025; /* Your accent color */
}

.summary-card .card-body {
  padding: 20px;
}

.summary-card h5 {
  margin-bottom: 15px;
  font-weight: 600;
}

.summary-card p {
  font-size: 20px;
  font-weight: 500;
}

.registration-number-clickable:hover {
  text-decoration: underline;
}

.registration-number-clickable {
  cursor: pointer;
}

.mr-4 {
  margin-right: 10px;
}
