.claimButton {
  color: white;
  padding: 5px;
  width: 120px;
  border-radius: 10px;
}

.claimButton[style*="green"] {
  background-color: green;
  color: white;
  padding: 5px;
  width: 120px;
  border-radius: 10px;
}

.claimButton[style*="red"] {
  color: white;
  padding: 5px;
  width: 120px;
  border-radius: 10px;
  background-color: red;
}
.OverviewButton {
  background-color: green;
  color: white;
  padding: 5px;
  width: 120px;
  border-radius: 10px;
}
.PrintButton {
  background-color: green;
  color: white;
  padding: 5px;
  width: 120px;
  border-radius: 10px;
}
.mainbutton {
  display: flex;
  gap: 20px;
}
.calimReq {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 20px;
}

.md-content .modal-content {
  background-color: #6c8368 !important;
}
/* .modal-content {
  background-color: #6c8368 !important;
} */
.md-header .modal-header {
  background-color: #6c8368 !important;
  color: white;
}
/* .modal-header {
  background-color: #6c8368 !important;
  color: white;
} */
.md-body .modal-body {
  background-color: #6c8368 !important;
  color: white;
  overflow-y: scroll;
}
/* .modal-body {
  background-color: #6c8368 !important;
  color: white;
} */
.spantext {
  font-weight: 600;
}
.ReportDescription {
  color: white;
  line-height: normal;
}

.custom-modal-header button .close {
  color: white !important;
}
.printpdf-sec {
  font-size: 20px !important;
  display: none;
  /* display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 20px; */
}
.ReportprintDescription {
  color: black;
  line-height: normal;
}
.spantextn {
  font-weight: 600;
  color: black;
}
.printReq {
  font-size: 20px !important;
  padding: 10%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.print-req-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.print-req-heading h2 {
  padding: 0px !important;
}

.printname {
  color: black;
}
.printimg {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  height: 50%;
}

@media print {
  .printpdf-sec {
    display: block;
  }
}

.oader-container-claim {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: transparent !important;
}

.loader-claim {
  border: 4px solid #ffffff !important;
  border-left-color: #2a4025 !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  background-color: transparent;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.nav {
  background-color: transparent;
  margin-right: 0 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid black !important;
}

.nav-item button {
  color: white;
}

.nav-item button:hover {
  color: black;
}

.claim-req-modal .modal-body {
  max-height: 82vh !important;
}

.scrollable-content {
  position: relative;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

.scrollable-content::-webkit-scrollbar {
  width: 13px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: white;
  padding-left: 5px;
  border-radius: 5px;
  border-left: 7px solid transparent;
  background-clip: padding-box;
  margin-top: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: transparent;
}

.error-text {
  color: rgb(173 16 16);
}

.repair-attachment {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.repair-attachment-container {
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  border: 1px solid white;
}

.file-attachment-item {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 150px;
}

.search-input input {
  padding-left: 2.5rem;
  margin-bottom: 0px !important;
  border-radius: 5px !important;
  border: 1px solid !important;
  border-color: hsl(0, 0%, 80%) !important;
}

.search-input {
  position: relative;
}

.search-input i {
  color: #6c737f;
  top: 32% !important;
  left: 14px;
  position: absolute;
  font-size: 20px;
}

.previous-claims-text {
  background-color: #f7f8fa !important;
}

.collapse-tr {
  padding: 0px !important;
  background-color: white !important;
}

.collapse-tr tr {
  border-bottom: 1px solid #e7e7e7 !important;
}

.collapse-tr td {
  vertical-align: middle;
}

.header-button-claim {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
