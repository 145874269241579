.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-35 {
  width: 35% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}
.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.space-x-2 {
  margin-left: 0.5rem;
}

.gap-x-2 {
  row-gap: 0.25rem;
}

.search-input input {
  padding-left: 2.5rem;
  margin-bottom: 0px !important;
  border-radius: 5px !important;
  border: 1px solid !important;
  border-color: hsl(0, 0%, 80%) !important;
}

.body-container {
  border-radius: 20px !important;
  margin-top: 20px !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
  box-shadow: 2px 3px 8px 0px hsl(0, 0%, 85%) !important;
}

.body-filter {
  padding: 16px 16px 16px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overflow-table {
  overflow: scroll;
  width: 100% !important;
}

.add-product-image img {
  border-radius: 5px !important;
  width: 120px;
  /* height: 120px; */
  object-fit: contain;
}

.product-image img {
  border-radius: 5px !important;
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.product-name-div {
  display: grid;
}

.product-name-div .product-cateory-name {
  color: hsl(0, 0%, 50%) !important;
  font-size: 14px !important;
}

.out-of-stock-badge {
  background-color: #ffe9e9;
  border: 1px solid #ff9d9d;
  color: #f04438;
  border-radius: 100px;
  padding: 0px 8px 0px 8px;
  font-size: 14px;
}

.search-input {
  position: relative;
}

.search-input i {
  color: #6c737f;
  top: 32% !important;
  left: 14px;
  position: absolute;
  font-size: 20px;
}

.td-content {
  padding: 20px 20px 20px 10px !important;
}

.custom-pagination {
  padding: 0px 15px 0px 15px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.change-image-btn {
  padding: 8px 16px 8px 16px;
  color: #2a4025 !important;
  border-radius: 10px !important;
  border: 1px solid gray;
  align-items: center;
}

.change-image-btn:hover {
  border: 1px solid gray !important;
  background-color: #fafafa !important;
}

.custom-numericInput-container {
  position: relative;
}

.custom-numericInput-container input {
  height: 50px;
  padding: 2px 10px 0px 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: solid 1px hsl(0, 0%, 80%);
  box-sizing: border-box;
  font-family: inherit;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.custom-numericInput-container label {
  position: absolute;
  top: 2px;
  left: 17px;
  pointer-events: none;
  /* color: #999; */
  transition: 0.3s;
  font-size: 10px;
  color: #091d1f !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.custom-numericInput-container input:focus + label,
.custom-numericInput-container input:not([value=""]):valid + label {
  top: 2px;
  left: 17px;
  font-size: 10px;
  /* color: #5264AE; */
  color: black !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.textarea-input {
  border-radius: 5px !important;
  margin-bottom: 20px !important;
  padding-top: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border: 1px solid !important;
  border-color: hsl(0, 0%, 80%) !important;
}

.error-number-border {
  border-color: red !important;
}


.custom-input-container label  {
  left: 20px !important;
}

.customSelect .custom-select-container label  {
  left: 20px !important;
}