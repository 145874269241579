.approveButton {
  color: white;
  padding: 5px;
  width: 120px;
  border-radius: 10px;
}

.approveButton[style*="green"] {
  background-color: green;
}

.approveButton[style*="red"] {
  background-color: red;
}
