.service_program_number {
  padding: 7px 17px 7px 17px;
  background-color: #4a6543;
  color: #fff;
}

.custom-numericInput-container {
  position: relative;
}

.custom-numericInput-container input {
  height: 50px;
  padding: 2px 10px 0px 10px;
  font-size: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: solid 1px hsl(0, 0%, 80%);
  box-sizing: border-box;
  font-family: inherit;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.custom-numericInput-container label {
  position: absolute;
  top: 2px;
  left: 17px;
  pointer-events: none;
  /* color: #999; */
  transition: 0.3s;
  font-size: 10px;
  color: #091d1f !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.custom-numericInput-container span {
  position: absolute;
  top: -3px;
  right: 17px;
}

.custom-numericInput-container i {
 font-size: 12px;
}

.custom-numericInput-container input:focus + label,
.custom-numericInput-container input:not([value=""]):valid + label {
  top: 2px;
  left: 17px;
  font-size: 10px;
  /* color: #5264AE; */
  color: black !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.space-x-2 {
  margin-left: 0.5rem;
}

.gap-x-2 {
  row-gap: 0.25rem;
}

.btn {
  z-index: 0 !important;
}

.non-ctive-program {
  background-color: #e5e7eb !important;
  color: black !important;
}

.isTierScreen {
  margin-bottom: 5px !important;
}

.container-tier-screen {
  border: solid 1px hsl(0, 0%, 80%);
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}
