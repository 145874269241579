.createClaimModal .modal-body {
  padding-bottom: 0px !important;
}

.createClaimModal .modal-dialog{
  width: 50% !important;
  max-width: 50% !important;
}

.object-cover {
  object-fit: cover !important;
}

.modal-overflow {
    height: 900px !important;
    overflow: auto;
}

.required{
    color: red;
}