.error-messagee {
  margin-top: -15px !important;
  color: red;
}

.p-tag-padding {
  padding: 10px 10px 10px 10px !important;
}

.print-landscape {
  font-size: 20px !important;
  display: none;
}

@media print {
  .print-landscape{
    display: block;
  }
  .print-landscape {
    /* display: block; */
     /* width: 100vh;
    height: 100vw; 
    transform: rotate(-90deg) translate(-100%);
    transform-origin: bottom left;  */
  }
}


.status-badge {
  font-size: 13px !important;
  padding: 10px 10px 10px 10px !important;
}

.pending-badge {
    color: #b54708 !important;
    background-color: #ffdeb5 !important;
    border: 1px solid #ff8b00;
}

.complete-badge {
  color: #107569 !important;
  background-color: #e3f6f3 !important;
  border: 1px solid #68ffe8;
}

.rejected-badge {
  color: #b42318 !important;
  background-color: #fde8e7 !important;
  border: 1px solid #fa9d98;
}
