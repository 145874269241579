.custom-select-container {
  position: relative;
}

/* .custom-select-container select {
  height: 50px;
  padding: 2px 10px 0px 10px;
  font-size: 15px;
  margin-bottom: 20px;
} */

.custom-select-container label {
  position: absolute;
  top: 2px;
  left: 7px;
  font-size: 10px;
  color: black !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.custom-select-container select:focus + label,
.custom-select-container select:not([value=""]):valid + label {
  top: 2px;
  left: 7px;
  font-size: 10px;
  /* color: #5264AE; */
  color: black !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.css-13cymwt-control {
  height: 50px;
  border-color: hsl(0, 0%, 80%) !important;
}

.css-1fdsijx-ValueContainer {
  height: 50px;
}

.css-hlgwow {
  height: 50px;
}

.css-qbdosj-Input {
  height: 40px;
  font-size: 16px !important;
}

.css-166bipr-Input {
  height: 40px;
  font-size: 16px !important;
  outline: none;
}

.css-166bipr-Input Input:focus {
  outline: none !important;
  border: none !important;
}

.css-166bipr-Input Input {
  border: transparent !important;
  height: 40px;
  font-size: 15px !important;
}

.css-qbdosj-Input input {
  height: 40px;
  font-size: 15px !important;
  border: none !important;
}

.css-1h01tm3-Input input {
  height: 40px;
  font-size: 15px !important;
}

.css-1dimb5e-singleValue {
  font-size: 15px !important;
  margin-top: 10px !important;
}
.css-olqui2-singleValue {
  font-size: 15px !important;
  margin-top: 10px !important;
}

.css-1jqq78o-placeholder {
  font-size: 15px !important;
  margin-top: 10px !important;
}

.css-t3ipsp-control {
  height: 50px !important;
  /* border-color:!important */
  border: 1px solid hsl(0, 0%, 40%) !important;
  box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
  /* display: none; */
}

.error-select-message {
  margin-top: 3px !important;
  color: red !important;
  font-size: 14px !important;
}

.error-border .css-13cymwt-control {
  border-color: red !important;
}

.no-label-in-select .custom-select-container label {
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  font-size: 10px;
  color: black !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.no-label-in-select .custom-select-container select:focus + label,
.no-label-in-select
  .custom-select-container
  select:not([value=""]):valid
  + label {
  top: 0px !important;
  left: 0px !important;
  font-size: 10px;
  /* color: #5264AE; */
  color: black !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.no-label-in-select .css-1dimb5e-singleValue {
  margin-top: 0px !important;
}

.no-label-in-select .css-olqui2-singleValue {
  margin-top: 0px !important;
}

.no-label-in-select .css-1jqq78o-placeholder {
  margin-top: 0px !important;
}

.multi-select .css-3w2yfm-ValueContainer  {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.multi-select .css-1dyz3mf {
  display: flex !important;
  flex-wrap: nowrap !important;
}


.css-1nmdiq5-menu .css-9fkfkq-MenuPortal {
  z-index: 50 !important;
  position: relative !important;
}
