* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins";
}
html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
a,
p,
.row,
.col {
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}

.pr-1 {
  padding-right: 0.25rem !important;
}


// ---------------custom margins

.mt-30 {
  margin-top: 30px;
}

// ---------------custom image sizes
.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input {
  outline: none;
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-outer-spin-button {
    display: none;
  }
}
h1.heading-service-type {
  // background: #4a6543;
  color: white;
  font-size: 23px;
  padding: 10px;
}
.offerandservice {
  display: flex;
  // gap: 60px;
  // background-color: red;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  .offernameandprice {
    // background-color: #4a6543;
    h6 {
      &:first-child {
        display: flex;
        align-items: center;
        // background-color: aqua;
        width: 100%;
      }
      &:last-child {
        display: flex;
        align-items: center;
        justify-content: end;
        // background-color: rgb(202, 21, 202);
        width: 30%;
      }
    }
  }
}

.accordion-item {
  color: var(--bs-accordion-color);
  /* background-color: var(--bs-accordion-bg); */
  /* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); */
  border: none !important;
  border-radius: none !important;
}
div.options > label > input {
  visibility: hidden;
}

div.options > label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

div.options > label > img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

div.options > label > input:checked + img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}

.offerandservice2 {
}
// .servicenamewrapper {
//   display: flex;
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   padding: 10px;
//   flex-direction: column;
// }
.serviceChecks {
  display: flex;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px 10px;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: flex-start;
  justify-content: space-between;
}
.serviceChecks input {
  margin: 0px !important;
}
.serviceChecks span {
  padding: 0px !important;
}
.ps-4 {
  width: 30%;
  text-align: end;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.serviceChecks div {
  display: flex;
  align-items: center;
  // gap: 10px;
  column-gap: 10px;
}

.ServiceChecks {
  display: flex;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
}

.SearchReg {
  padding: 20px;
  width: 100%;
  // display: flex;
}
.secondrow {
  display: flex;
  width: 60%;
  gap: 20px;
  // justify-content: center;
  align-items: center;
}
.mainfilter {
  display: flex;
  width: 60%;
  gap: 20px;
  align-items: center;
}

.form-select-option {
  height: 65px;
  margin-bottom: 40px;
  color: #000;
  border: 2px solid #e4ecf2;
  // width: 42% !important;
}
.registrant-no {
  max-width: 300px;
}
.btn-clear {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a4025;
  color: rgb(255, 255, 255);

  font-size: 16px;
  margin-top: 35px;
  margin-left: 10px;

  height: 45px;
}
.Clear {
  /* padding: 15px 30px; */
  background-color: #2a4025;
  color: rgb(255, 255, 255);
  /* margin-bottom: 30px; */
  /* margin-left: 40px; */
  font-size: 16px;

  display: flex;
  gap: 10px;
  align-items: center;
}

.serchclear {
  // padding: 5px 30px;
  // background-color: #2a4025;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  // width: 100%;

  height: 45px;
}
.search {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #2a4025;
  color: rgb(255, 255, 255);
  /* margin-bottom: 30px; */
  /* margin-left: 40px; */
  font-size: 16px;
  width: 120px;
  padding: 5px 20px;
  justify-content: center;
}
.showreg-table thead {
  background-color: #4a6543;
}
.showreg-table thead tr th {
  padding: 20px;
  color: white;
}
// .showreg-table tbody tr th:nth-child(3),
// td:nth-child(5) {
//   min-width: 300px;
// }

.showreg-table tbody tr td {
  padding: 20px;
  border-bottom: 1px solid black;
}
// .showreg-table tbody tr td:nth-child(3),
// td:nth-child(5) {
//   min-width: 300px;
// }
@media screen and (max-width: 767px) {
  .btn-clear {
    width: 170px;
    margin-bottom: 50px;
    margin-top: 0px;
    margin-left: 12.5px;
  }
}

.input-item {
  .headeroffer {
    margin-top: 20px;

    .accordion-item:last-of-type .accordion-button {
      justify-content: space-between !important;

      .headingbottom {
        padding-right: 40px;
      }
    }

    .accordion-button::after {
      margin-left: 0 !important;
      position: absolute;
      right: 27px;
    }
  }
  .ltn__my-properties-table.table-responsive {
    padding-top: 30px;
  }
}

.space-y-2{
  margin-top: 0.5rem;
}

.viewall-content {
  .viewall-header {
    background-color: #6c8368 !important;
    color: white;
  }
  .viewall-md {
    background-color: #6c8368 !important;
    .regReq {
      display: grid !important ;
      flex-direction: column;
      gap: 10px;
      font-size: 20px;
      grid-template-columns: repeat(2, 1fr);
      .selectuser {
        margin: 0 !important;
        color: white;
        padding-bottom: 10px;
        border-bottom: 1px solid #ffffff;
      }
      .spanselectuser {
        font-weight: 600;
        margin: 0 !important;
        color: black;
      }
    }
  }
}

.ac-Body {
  background-color: white !important;
}

.theadprint {
  background: white;
}

.btn-backk {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
@media screen and (max-width: 992) {
  .sm-break {
    display: flex !important;
    flex-direction: column !important;
  }
}
.reportslider {
  padding: 5px;
  outline: none;
  border: 1px solid #cecece;
  max-width: 400px;
  .service-option {
    // text-wrap: wrap;
  }
}
.reportslider::-webkit-scrollbar {
  display: none;
}
.model-bdReport {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  color: white;
  .reportMod {
    color: white;
    line-height: normal;
    display: flex;
    gap: 5px;
    width: 100%;
    margin: 0px !important;
    .reportspan {
      color: black;
      font-weight: 600;
    }
  }
  .reportModOffer {
    color: white;
    line-height: normal;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    margin: 0px !important;
    .reportspan {
      color: black;
      font-weight: 600;
    }
  }
}

.sucess {
  padding: 30px 30px 0px 30px;
  .sucess_main {
    width: 100%;

    background: #fff;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(11, 11, 11, 0.35);
    box-shadow: 0px 0px 4px 0px rgba(11, 11, 11, 0.35);
    margin: 0 0 20px;
    /* opacity: 0; */
    filter: alpha(opacity=0);
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    overflow: hidden;
    border-left: 5px solid #65b561;
    .success_header {
      padding: 20px;
      background: #ecf6ec;
      .successs {
        display: flex;
        align-items: center;
      }
    }
    .success_content {
      padding: 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  .mobileTable {
    overflow-x: scroll;
    max-width: 100%;
    .table {
      width: 100vw;
    }
  }
}




.badge{
  --bs-badge-padding-x: 0.25em !important;
  --bs-badge-padding-y: 0.25em !important;
  --bs-badge-font-size: 0.55em !important;
  --bs-badge-font-weight: 700;
}

@media print {
  .no-print {
    display: none !important;
  }
}
