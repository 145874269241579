.errortext {
  color: red;
  margin-bottom: 10px;
  margin-left: 20px;
}

/* .regReq {
  display: grid !important ;
  flex-direction: column;
  gap: 10px;
  font-size: 20px;
  grid-template-columns: repeat(2, 1fr);
}

p {
  margin: 0 !important;
  color: white;
  padding-bottom: 10px;
  border-bottom: 1px solid #2a4025;
}

.spanselectuser {
  font-weight: 600;
  margin: 0 !important;
} */
.spanselectuser {
  color: black !important;
}

/* .viewall-md {
  
} */

.cursorPointerStyle {
  cursor: pointer;
}

.cursorPointerStyle:hover {
  color:#6c8368;
  text-decoration: underline;
}

.selectusername {
  color: white;
  padding-bottom: 10px;
  border-bottom: 1px solid #2a4025 !important;
}
.viewall-content .modal-content {
  background-color: #6c8368 !important;
}
/* .modal-content {
  background-color: #6c8368 !important;
} */
.viewall-header .modal-header {
  background-color: #6c8368 !important;
  color: white;
}
/* .modal-header {
  background-color: #6c8368 !important;
  color: white;
} */
.viewall-md .modal-body {
  background-color: #6c8368 !important;
  color: white;
}
.loader-container-All {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: transparent !important;
}

.loader-all {
  border: 4px solid #000000 !important;
  border-left-color: #2a4025 !important;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  background-color: transparent;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.reportlogModal .modal-body {
  min-height: 82vh;
  max-height: fit-content;
  overflow-y: scroll;
}