.MainDiv {
  padding: 70px 70px 70px 70px;
  width: 100%;
}

.w-full-updated {
  width: 100%;
}

.secDiv {
  display: flex;
  gap: 5px;
  align-items: Center;
  flex-direction: row;
  flex-wrap: wrap;
}

.thirdDiv {
  display: flex;
  gap: 5px;
  flex-direction: column;
  flex-wrap: wrap;
}

.fourDiv {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
}

.FontSizeWeight {
  font-size: 30px;
  font-weight: 600;
}

.fontsizeweighsm {
  font-weight: 500;
  font-size: 16px;
}

.flexwidth {
  display: flex;
  width: 100%;
}

.grid-vertical-center {
  width: 10%;
  display: grid;
  place-content: center;
}
.width80 {
  width: 80%;
}
.width70 {
  width: 70%;
}

.width55 {
  width: 55%;
}

.width20 {
  width: 20%;
}

.width15 {
  width: 15%;
}

.cutsombox {
  border-radius: 20px;
  margin-top: 50px;
  border: 1px solid hsl(0, 0%, 80%);
  box-shadow: 2px 3px 8px 0px hsl(0, 0%, 85%);
  border-radius: 20px;
  margin-top: 50px;
  border: 1px solid hsl(0, 0%, 80%);
  box-shadow: 2px 3px 8px 0px hsl(0, 0%, 85%);
}

.cutsomboxpadding {
  padding: 30px 30px 30px 30px;
}

.ddlex {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.gapdlex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.rowdlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.img-attachment {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
