body {
  /* background: #f1f1f1; */
  padding-top: 24px;
  /* text-align: center; */
  font-family: arial;
}

.mainTOggle {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -99999px;
}

input[type="checkbox"].toggle + label {
  height: 29px;
  line-height: 40px;
  background-color: #ccc;
  padding: 0px 10px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.25s ease-in;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
}

input[type="checkbox"].toggle + label:before,
input[type="checkbox"].toggle + label:hover:before {
  content: " ";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  background: #fff;
  z-index: 2;
  transition: all 0.25s ease-in;
  border-radius: 14px;
}

input[type="checkbox"].toggle + label .off,
input[type="checkbox"].toggle + label .on {
  color: #fff;
}

input[type="checkbox"].toggle + label .off {
  margin-left: 46px;
  display: inline-block;
}

input[type="checkbox"].toggle + label .on {
  display: none;
}

input[type="checkbox"].toggle:checked + label .off {
  display: none;
}

input[type="checkbox"].toggle:checked + label .on {
  margin-right: 46px;
  display: inline-block;
}

input[type="checkbox"].toggle:checked + label,
input[type="checkbox"].toggle:focus:checked + label {
  background-color: #55744e;
}

input[type="checkbox"].toggle:checked + label:before,
input[type="checkbox"].toggle:checked + label:hover:before {
  background-position: 0 0;
  top: 2px;
  left: 58%;
  /* margin-left: -48px; */
  right: 2px;
}

/* p {
  &:first-of-type {
    margin-top: 24px;
  }
  font-size: 16px;
  color: #717171;
  margin: 0;
} */
