.custom-input-container {
  position: relative;
}

.custom-input-container label {
  position: absolute;
  top: 2px;
  left: 7px;
  font-size: 10px;
  color: black !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.custom-input-container input:focus + label,
.custom-input-container input:not([value=""]):valid + label {
  top: 2px;
  left: 20px;
  font-size: 10px;
  /* color: #5264AE; */
  color: black !important;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.custom-input-container input {
  border-radius: 5px !important;
  margin-bottom: 20px !important;
  padding-top: 5px !important;
  padding-left: 7px !important;
  padding-right: 5px !important;
  border: 1px solid !important;
}

.input-border-color input {
  border-color: hsl(0, 0%, 80%) !important;
}
.error-input input {
  border-color: red !important;
}

.error-message {
  font-size: 14px !important;
}

.tool-tip-custom-input{
    position: absolute;
}