/* New CSS */
.reset-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  padding-bottom: 20px;
}
.modal-header {
  /* border: none !important; */
  background-color: #ffffff;
}
.reset-modal h4,
p {
  line-height: 100%;
}
.reset-modal .ltn__form-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
}
.reset-modal .ltn__form-box .btn-wrapper {
  width: 100%;
}
.reset-modal .ltn__form-box input {
  margin: 0px;
}

.form-login-container {
  position: relative;
  height: calc(100vh - 60px);
  padding: 50px 20px 20px 20px;
  /* border: 1px solid red; */
  background: url("/src/asset/login.jpg");
  background-size: 80% 80%;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.ltn__form-box-login {
  max-width: 700px;
  margin: auto;
  /* border: 1px solid red; */
}

/* New CSS */

.ltn__login-area-1 {
  /* background-image: url("/public/assets/img/selfimgs/login.jpg"); */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background: -webkit-gradient(linear, left top, right top, from(#758FA4), to(#4A6543));
  background: linear-gradient(to right, #758FA4, #4A6543); */
  /* border: 1px solid green !important; */
  height: 100%;
}

.containerss {
  display: flex;
  justify-content: center !important;
  min-height: 100vh;
  background: #ffffff;
  /* background: #3f5a3837; */

  /* background: #3f5a3882; */
  /* background: #3f5a3898; */
  /* background: linear-gradient(#3f5a3854, #3F5A38); */
  /* background-color: #4A6543; */
  /* background: linear-gradient(#f2f6f7b5, #4d5e6dcf); */
  /* border: 1px solid red !important; */
}

.login-conatiner-custom-main {
  /* justify-content: center !important; */
  /* flex-direction: row-reverse !important; */
  /* padding: 20px; */
  /* border: 1px solid red !important; */
  padding: 0 !important;
}

.logo-head-stip {
  background: linear-gradient(#3f5a38, #374b32);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 60px;
}
.logo-head-stip a {
  margin-bottom: -50px !important;
  cursor: pointer !important;
  z-index: 1;
}
.login-logo-head {
  width: 200px;
  z-index: 999;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: rgba(215, 231, 211, 0.982);
  /* background-color: #FFFFFF; */
}

.login-img-container {
  background-image: url("/public/assets/img/selfimgs/login.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  padding: 0 !important;
  margin: 0 !important;
  object-fit: cover !important;
  background-color: #ffffff;
  /* border: 1px solid red !important; */
}

.login-img-overlay {
  width: 100%;
  height: 100%;
  padding: 50px 30px 30px 40px;
  background-color: #0000008a;
  /* background-color: #4A6543c6; */
  /* background: -webkit-gradient(linear, left top, right top, from(#758FA4), to(#4A6543));
  background: linear-gradient(to bottom, #758fa4b9, #4A6543a8); */
}

.login-img-overlay h2 {
  font-size: 70px;
  line-height: 80%;
  color: #fafafa;
  text-align: left;
  /* color: #071C1F; */
}

.login-img-overlay h2:nth-child(2) {
  color: #4a6543;
  /* color: #071C1F; */
}

.login-img-overlay p {
  color: #fafafa;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 1.5px;
}

.login-socialMedia-container h5 {
  color: #fafafa;
  letter-spacing: 1px;
}

.login-socialMedia-container a {
  padding-right: 12px;
}

.login-socialMedia-container a .login-socailMedia-icons {
  width: 25px;
  height: 25px;
}

.login-img-container img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100% !important;
  object-fit: cover;
}

.login-conatiner-custom {
  /* height: 450px; */
  /* -webkit-box-shadow: 0 0 4px 0 rgba(11, 11, 11, 0.35); */
  /* box-shadow: 0 0 4px 0 rgba(11, 11, 11, 0.35); */
  /* background-color: #FFFFFF; */
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100% !important;
  padding-top: 50px;
  height: calc(100vh - 60px);
  /* border: 1px solid red !important; */
  /* padding: 20px; */
  /* margin-top: 200px !important; */
}

.login-car-img-container {
  /* display: flex; */
  /* border: 1px solid red; */
  background-image: url("/public/assets/img/selfimgs/login-car.png");
  height: 100%;
}

.login-car-img-container img {
  /* width: 100%; */
  border: 1px solid red;
  /* height: 30% !important; */
}

.account-login-inner {
  /* border: 1px solid green !important; */
  /* margin-top: -100px !important; */
}

.loginCar-img {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35%;
}

.ltn__form-box label {
  color: #091d1f;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.ltn__form-box input {
  background-color: transparent;
  border: none;
  border: 2px solid #4a65435d !important;
  color: #091d1f;
}

.ltn__form-box input:focus {
  border: none;
  border: 2px solid #4a6543 !important;
  /* border: 1px solid #4A6543 !important; */
  /* outline: #4A6543; */
}

.ltn__form-box input::placeholder {
  letter-spacing: 0px;
  color: #4a6543;
}

input::placeholder {
  letter-spacing: 0px;
  color: #4a6543;
}

.black-btn {
  padding: 10px 100px 9px;
}

.btn {
  padding: 15px 20px 13px;
}

.loginRest {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-top: 20px;
  letter-spacing: 1px;
  color: #091d1f;
  font-weight: 600;
}

.loginRest a small {
  /* font-size: 14px !important; */
  font-size: 16px;
  color: #091d1f;
  font-weight: 600;
  letter-spacing: 1px;
}

.loginRest .createAccount-link {
  color: #091d1f !important;
  font-weight: 400 !important;
  /* font-size: 14px !important;*/
}

.loginRest a small:hover {
  color: #4a6543 !important;
}

.loginRest a:hover {
  color: #4a6543 !important;
}

.loginRest .createAccount-link:hover {
  color: #4a6543;
}

.form-select-option {
  height: 65px;
  margin-bottom: 40px;
  color: #000;
  border: 2px solid #e4ecf2;
}

.form-select-option option {
  color: #000;
}

.form-select-option:focus {
  outline: none !important;
}

select:focus {
  outline: none !important;
}
.remember-me {
  gap: 10px;
}
.rememberMe {
  width: 20px;
  height: 20px;
}
.signin-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  letter-spacing: 1.5px;
  background-color: #4a6543 !important;
}

.error-text {
  color: red;
  margin-bottom: 3px;
}
.error-text-resetpass {
  color: red;
  text-decoration: underline;
  font-weight: 500;
}
.error-text-resetpass:hover {
  color: red;
  text-decoration: none;
}

.reset-password-textgap {
  margin-top: 20px !important;
}

.modal-body {
  /* padding-bottom: 60px; */
  background-color: #ffffff;
}

.modal-product-info h4 {
  margin-bottom: 35px;
}
.modal-product-info {
  padding: 0px 20px;
}
.close {
  background-color: #ffffff;
  -webkit-transition: none !important;
}
.newpass {
  position: relative;
  width: 100%;
}
.eyeicon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 17px;
  color: black;
}

input[type="password"] {
  letter-spacing: 0px !important;
  font-size: 16px;
}

@media only screen and (max-width: 1400px) {
  .form-login-container {
    background: none !important;
  }
}

@media only screen and (max-width: 920px) {
  .login-conatiner-custom {
    width: 100% !important;
  }

  .login-img-container {
    width: 100% !important;
  }

  .login-conatiner-custom-main {
    flex-direction: column;
    height: 100% !important;
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .containerss {
    padding: 0;
    margin: 0;
  }

  .col-6 {
    width: 95%;
  }

  .ltn__form-box {
    padding: 10px 0px 30px 0px;
  }

  .login-conatiner-custom-main {
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 400px) {
  .loginRest a small {
    text-align: right;
  }

  .loginRest .createAccount-link {
    /* text-align: right; */
  }

  .signin-btn {
    padding: 10px !important;
    font-size: 14px !important;
  }
}
