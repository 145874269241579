.custom-button-class {
    /* border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: normal;
    border: 1px solid;
    padding: 2px 4px;
    font-size: 1rem;
    font-weight: normal;
    color: #ffffff;
    gap: 1px;
    text-transform: capitalize; */
}


.button-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.danger-btn {
    background-color:  rgb(255 118 118) !important;
}