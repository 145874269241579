.CustomizeTaxModal {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 20px;
}
.includeGST {
  width: 32%;
}
.hstCheckBox {
  display: flex;
  gap: 10px;
}
#gstRate,
#pstRate {
  height: 45px !important;
}
#includeGST {
  width: 30%;
}
.btn-customizeTax {
  padding: 15px 30px;
  background-color: rgb(74, 101, 67);
  color: #fff;
  margin-bottom: 30px;
  margin-left: 10px;
  font-size: 16px;
}
.selectdropdown {
  height: 52px !important;
  margin-bottom: 40px;
  color: #000;
  border: 2px solid #e4ecf2;
}
.accord-Header button {
  background: transparent !important;
  color: #000 !important;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3) !important;
  border-bottom: 4px solid #4a6543 !important;
}
.accord-Header button.collapsed {
  background-color: transparent !important;
  border-bottom: 4px solid #4a6543 !important;
}

.dropBtn-after button::after {
  filter: invert(1) !important;
}
.eyeiconn {
  cursor: pointer;
  position: absolute;
  right: 35px;
  top: 42px;
}
.mainpass {
  position: relative;
}
.error-msg {
  margin-top: -18px !important;
  color: red;
}
.input-item .nice-select {
  background-position-y: 50%;
}
@media (max-width: 1050px) {
}
/* .nice-select {
  width: 280px !important;
} */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: transparent !important;
}

.loader {
  border: 4px solid #ffffff !important;
  border-left-color: #2a4025 !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  background-color: transparent;
}

.confirmmodal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}
.confirmmodal .modal-body h4 {
  font-size: 30px;
  font-weight: 600;
  line-height: 100%;
}
.confirmmodal .modal-body p {
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
}
.confirmmodal .modal-body p,
h4 {
  margin: 0px;
}
.confirmmodal .modal-body button {
  margin-top: 30px;
  padding: 8px 0px;
  width: 200px;
  background: #3f5a38;
  border-radius: 8px;
  color: white;
  font-family: var(--body-font);
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.md-body {
  background-color: #6c8368 !important;
  color: white;
  height: 100%;
  overflow-y: scroll;
}
/* .md-content {
  background-color: #6c8368 !important;
} */

.tier-match {
  font-size: 13px !important;
  padding-left: 5px !important;
  padding-top: 3px !important;
  color: red !important;
}
